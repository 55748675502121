import type { RootState } from '@/store';
import type { ActionContext } from 'vuex';

export type CustomerStateType = {
  customer?: any,
};

export default {
  namespaced: true,
  state: (): CustomerStateType => ({
    customer: undefined
  }),
  getters: {
    getCustomer: function (state: CustomerStateType): any | undefined {
      return state.customer;
    }
  },
  mutations: {
    setCustomer: function (state: CustomerStateType, value?: any): void {
      state.customer = value;
    }
  },
  actions: {
    setSelectedCustomer: function ({commit}: ActionContext<CustomerStateType, RootState>, value: any): any {
      commit('setCustomer', value);
    }
  }
};
