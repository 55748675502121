<template>
  <v-app>
    <loading />
    <v-main>
      <v-dialog v-if='$router.currentRoute.name === route.SIGN_IN ? false : getSignInPageVisibility' :value='true' fullscreen persistent>
        <sign-in />
      </v-dialog>

      <div>
        <app-bar v-if='$router.currentRoute.name === route.SIGN_IN ? false : !getSignInPageVisibility && reportRoutes.indexOf($router.currentRoute.name) === -1' />
        <app-bar-drawer v-if='$router.currentRoute.name === route.SIGN_IN ? false : !getSignInPageVisibility && reportRoutes.indexOf($router.currentRoute.name) === -1' />
        <router-view v-show='!($router.currentRoute.name === route.SIGN_IN ? false : getSignInPageVisibility)' />
      </div>
    </v-main>
  </v-app>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { Route } from './router';

  export default {
    name: 'App',
    components: {
      AppBar: () => import('./components/Core/AppBar'),
      AppBarDrawer: () => import('./components/Core/AppBarDrawer'),
      Loading: () => import('./components/Loading/Loading'),
      SignIn: () => import('./pages/Auth/Auth'),
    },
    data () {
      return {
        reportRoutes: ['ExampleReport', 'PrintDistribution', 'CustomerPrint'],
      };
    },
    computed: {
      ...mapGetters('auth', [
        'getSignInPageVisibility',
      ]),
      route: function () {
        return Route;
      },
    },
    mounted: function () {
      this.$store.dispatch('auth/getLoggedUser');
    },
  };
</script>

<style lang='scss' scoped>
  ::v-deep.v-application {
    background-color: #f2edf9 !important;
  }
</style>
