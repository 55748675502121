import Vue from 'vue';
import VueRouter from 'vue-router';
import type { RouteConfig } from 'vue-router';
import store from '@/store';
import type { UserType } from '@/store/modules/Auth';
import { ACCOUNT_ROLE } from '@/store/modules/Auth';

Vue.use(VueRouter);

async function getUser (): Promise<{ details: UserType | undefined, isAdmin: boolean, }> {
  let user: UserType | undefined = store.getters['auth/getUser'];

  if (!user) {
    await store.dispatch('auth/getLoggedUser');
    user = store.getters['auth/getUser'];
  }

  return {
    details: user,
    isAdmin: user?.account.accountRoleId === ACCOUNT_ROLE.ADMIN || user?.account.accountRoleId === ACCOUNT_ROLE.SUPER_ADMIN
  };
}

export const Route = {
  HOME: 'Home',
  SIGN_IN: 'SignIn',
  USERS: 'Users',
  PRINTERS: 'Printers',
  ACCESS_DENIED: 'AccessDenied',
  PROFILE: 'Profile',
  RELEASE_NOTES: 'ReleaseNotes',
  STOCK: 'Stock',
  STOCK_SHIPMENT: 'StockShipment',
  STOCK_DISCARD: 'StockDiscard',
  REPORTS: 'Reports',
  IMPORT: 'Import',
  LAYOUT_UPDATED: 'LayoutUpdated',
  CONFIG_LAYOUT: 'Config Layout',
  EXAMPLE_REPORT: 'ExampleReport',
  PRINT_DISTRIBUTION: 'PrintDistribution',
  CUSTOMER_PRINT: 'CustomerPrint',
  CUSTOMERS: 'Customers',
  IMPORTER: 'Importer',
  RECORD_SEARCH: 'RecordSearch',
  RECORD_VALIDATION: 'RecordValidation'
};

const routes: RouteConfig[] = [
  {
    path: '/',
    name: Route.HOME,
    component: async (): Promise<any> => import(/* webpackChunkName: "home" */ '../pages/Home/Home.vue')
  },
  {
    path: '/access-denied',
    name: Route.ACCESS_DENIED,
    component: async (): Promise<any> => import(/* webpackChunkName: "home" */ '../pages/Core/AccessDenied.vue')
  },
  {
    path: '/sign-in',
    name: Route.SIGN_IN,
    component: async (): Promise<any> => import(/* webpackChunkName: "auth" */ '../pages/Auth/Auth.vue')
  },
  {
    path: '/release-notes',
    name: Route.RELEASE_NOTES,
    component: async (): Promise<any> => import(/* webpackChunkName: "release-notes" */ '../pages/ReleaseNotes/ReleaseNotes.vue')
  },
  {
    path: '/stock-entry',
    name: Route.STOCK,
    component: async (): Promise<any> => import(/* webpackChunkName: "new-stock" */ '../pages/Stock/Stock.vue')
  },
  {
    path: '/stock-shipment',
    name: Route.STOCK_SHIPMENT,
    component: async (): Promise<any> => import(/* webpackChunkName: "new-stock" */ '../pages/Stock/StockShipment.vue')
  },
  {
    path: '/stock-discard',
    name: Route.STOCK_DISCARD,
    component: async (): Promise<any> => import(/* webpackChunkName: "new-stock" */ '../pages/Stock/discardStock.vue')
  },
  {
    path: '/reports',
    name: Route.REPORTS,
    component: async (): Promise<any> => import(/* webpackChunkName: "new-stock" */ '../pages/Reports/Reports.vue')
  },
  {
    path: '/import',
    name: Route.IMPORT,
    component: async (): Promise<any> => import(/* webpackChunkName: "new-stock" */ '../pages/Documents/Import.vue')
  },
  {
    path: '/config-layout',
    name: Route.CONFIG_LAYOUT,
    component: async (): Promise<any> => import(/* webpackChunkName: "config-layout" */ '../pages/Config/Layout.vue')
  },
  {
    path: '/layout-configuration',
    name: Route.LAYOUT_UPDATED,
    component: async (): Promise<any> => import(/* webpackChunkName: "layout-updated" */ '../pages/Config/LayoutUpdated.vue')
  },
  {
    path: '/users',
    name: Route.USERS,
    component: async (): Promise<any> => import(/* webpackChunkName: "users" */ '../pages/Users/Users.vue'),
    beforeEnter: async function (_to, _from, next): Promise<void> {
      const user = await getUser();
      if (user.isAdmin) {
        next();
      } else if (user.details?.account.id) {
        next('/access-denied');
      }
    }
  },
  {
    path: '/printers',
    name: Route.PRINTERS,
    component: async (): Promise<any> => import(/* webpackChunkName: "users" */ '../pages/Printer/Printer.vue'),
    beforeEnter: async function (_to, _from, next): Promise<void> {
      const user = await getUser();
      if (user.isAdmin) {
        next();
      } else if (user.details?.account.id) {
        next('/access-denied');
      }
    }
  },
  {
    path: '/profile',
    name: Route.PROFILE,
    component: async (): Promise<any> => import(/* webpackChunkName: "profile" */ '../pages/Profile/Profile.vue')
  },
  {
    path: '/example-report',
    name: Route.EXAMPLE_REPORT,
    component: async (): Promise<any> => import(/* webpackChunkName: "exempleReport" */ '../reports/ExampleReport/ExampleReport.vue')
  },
  {
    path: '/print-distribution',
    name: Route.PRINT_DISTRIBUTION,
    component: async (): Promise<any> => import(/* webpackChunkName: "printDistribution" */ '../reports/PrintDistribution/PrintDistribution.vue')
  },
  {
    path: '/customer-print',
    name: Route.CUSTOMER_PRINT,
    component: async (): Promise<any> => import(/* webpackChunkName: "customerPrint" */ '../reports/CustomerPrint/CustomerPrint.vue')
  },
  {
    path: '/customers',
    name: Route.CUSTOMERS,
    component: async (): Promise<any> => import(/* webpackChunkName: "customers" */ '../pages/Customer/Customer.vue')
  },
  {
    path: '/importer',
    name: Route.IMPORTER,
    component: async (): Promise<any> => import(/* webpackChunkName: "printer" */ '../pages/Importer/Importer.vue')
  },
  {
    path: '/record-search',
    name: Route.RECORD_SEARCH,
    component: async (): Promise<any> => import(/* webpackChunkName: "printer" */ '../pages/RecordSearch/RecordSearch.vue')
  },
  {
    path: '/record-validation',
    name: Route.RECORD_VALIDATION,
    component: async (): Promise<any> => import(/* webpackChunkName: "printer" */ '../pages/RecordValidation/RecordValidation.vue')
  }
];

const router = new VueRouter({
  routes: routes
});

export default router;
