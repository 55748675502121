import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#380281', // 009bd5
        secondary: '#7919FA',
        tertiary: '#CFB8EE',
        accent: '#e1d1f6'
      }
    }
  },
  lang: {
    locales: {
      // eslint-disable-next-line
      ptbr: require('vuetify/lib/locale/pt').default,
    },
    current: 'ptbr'
  }
});
