import axios from 'axios';

import type {ActionContext} from 'vuex';
import type {RootState} from '@/store';

export enum ACCOUNT_ROLE {
  SUPER_ADMIN = 1,
  ADMIN = 2,
  USER = 3,
  VALIDATOR = 4
}

export enum ACCOUNT_STATUS {
  ACTIVATED = 1,
  DISABLED = 2,
  INACTIVATED = 3
}

export enum ACCOUNT_STATUS_ENUM {
  ACTIVATED = 'ACTIVATED',
  DISABLED = 'DISABLED',
  INACTIVATED = 'INACTIVATED'
}

export type AccountType = {
  id?: number,
  name?: string,
  email?: string,
  password?: string,
  cpf?: string,
  createdAt?: any,
  updatedAt?: any,
  deletedAt?: any,
  accountDetailsId?: number,
  accountStatusId?: number,
  accountRoleId?: number,
  accountCreatorId?: number,
  accountCreatorName?: string,
  accountStatusDescription?: string,
  accountRoleDescription?: string,
};

export type AccountStatusType = {
  id?: number,
  code: ACCOUNT_STATUS_ENUM,
};

export type AccountDetailsType = {
  id?: number,
  lastSignIn?: any,
  recoverCode?: string | null,
  recoverCodeDate?: any | null,
  activationDate?: any | null,
  activationCode?: string | null,
  activationCodeDate?: any | null,
  countFailedRecoveryAttempts?: number,
  countFailedSignInAttempts?: number,
  lastFailedSignInAttemptDate?: any | null,
};

export type UserType = {
  account: AccountType,
  details: AccountDetailsType,
};

export type AuthStateType = {
  isSignInPageVisible: boolean,
  user?: UserType,
};

export default {
  namespaced: true,
  state: (): AuthStateType => ({
    isSignInPageVisible: false,
    user: undefined
  }),
  getters: {
    getUser: function (state: AuthStateType): UserType | undefined {
      return state.user;
    },
    getSignInPageVisibility: function (state: AuthStateType): boolean {
      return state.isSignInPageVisible;
    }
  },
  mutations: {
    setUser: function (state: AuthStateType, value?: UserType): void {
      state.user = value;
    },
    setSignInPageVisibility: function (state: AuthStateType, value: boolean): void {
      state.isSignInPageVisible = value;
    }
  },
  actions: {
    getLoggedUser: async function ({commit}: ActionContext<AuthStateType, RootState>): Promise<any> {
      const baseURL = process.env.VUE_APP_USE_PLATFORM_LOGIN_REDIRECT === 'true' ? process.env.VUE_APP_PLATFORM_SERVICE_URL : process.env.VUE_APP_REQUEST_BASE_URL;

      const { data } = await axios({
        url: '/account-logged-user',
        baseURL,
        method: 'GET',
        responseType: 'json',
        withCredentials: true
      });

      if (data?.account) {
        commit('setUser', {
          account: data.account,
          details: data.details
        });
      } else {
        commit('setUser', undefined);
      }
    },
    signOut: async function ({commit}: ActionContext<AuthStateType, RootState>): Promise<any> {
      const baseURL = process.env.VUE_APP_USE_PLATFORM_LOGIN_REDIRECT === 'true' ? process.env.VUE_APP_PLATFORM_SERVICE_URL : process.env.VUE_APP_REQUEST_BASE_URL;

      await axios({
        url: '/account-sign-out',
        baseURL,
        method: 'POST',
        responseType: 'json',
        withCredentials: true
      });

      commit('setUser', undefined);
    },
    toggleSignInPageVisibility: function ({commit, getters}: ActionContext<AuthStateType, RootState>, payload: {value: boolean, }): void {
      commit('setSignInPageVisibility', payload?.value !== undefined ? payload.value : !getters.getSignInPageVisibility);
    }
  }
};
