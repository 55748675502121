import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import type {AuthStateType} from './modules/Auth';
import Auth from './modules/Auth';
import type { CustomerStateType } from './modules/Customer';
import Customer from './modules/Customer';
import type {LoadingStateType} from './modules/Loading';
import Loading from './modules/Loading';
import type {DrawerStateType} from './modules/Drawer';
import Drawer from './modules/Drawer';

Vue.use(Vuex);

export type RootState = {
  Auth: AuthStateType,
  Loading: LoadingStateType,
  Drawer: DrawerStateType,
  Customer: CustomerStateType,
};

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  reducer: (state: RootState): any => ({auth: state.Auth})
});

const store = new Vuex.Store<RootState>({
  modules: {
    auth: Auth,
    drawer: Drawer,
    loading: Loading,
    customer: Customer
  },
  plugins: [vuexLocal.plugin]
});

export default store;
