import type { ActionContext } from 'vuex';
import type { RootState } from '@/store';

export type DrawerStateType = {
  drawerVisibility: boolean,
};

export default {
  namespaced: true,
  state: (): DrawerStateType => ({
    drawerVisibility: true
  }),
  getters: {
    getDrawerVisibility: function (state: DrawerStateType): boolean {
      return state.drawerVisibility;
    }
  },
  mutations: {
    setDrawerVisibility: function (state: DrawerStateType, value: boolean): void {
      state.drawerVisibility = value;
    }
  },
  actions: {
    toggleDrawerVisibility: function ({ commit, getters }: ActionContext<DrawerStateType, RootState>, payload: { value: boolean, }): void {
      commit('setDrawerVisibility', payload?.value !== undefined ? payload.value : !getters.getDrawerVisibility);
    }
  }
};
